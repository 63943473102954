import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

const CurrentProjectWrapper = styled.div`
  position: absolute;
  bottom: 30px;
  right: 40px;
  width: 12%;
  min-width: 150px;
  max-width: 200px;
  z-index: 3;
  display: none;
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    display: block;
  }
  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    display: block;
  }
  &.light{
    a{
      color: white;
    }
  }

  a {
    color: black;
    font-size: 18px;
    letter-spacing: 1px;
    text-decoration: none;
    display: block;
    text-align: center;
    margin-bottom: 10px;
  }
`

const CurrentProjectImg = styled(Img)`
  z-index: 2;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);

  &:after {
    content: "";
    border-radius: 5px;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // -webkit-transition: all 1s cubic-bezier(0.165, 0.84, 0.44, 1);
    // transition: all 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  
  &:hover {
    transform: scale(1.15, 1.15);
  }
  
  &:hover{
    &:after {
        opacity: 1;
    }
  }
`

const CurrentProject = ({ slug, projectPost, dark }) => {
  return (
    <CurrentProjectWrapper className={dark === true ? `light` : `dark`}>
        <Link to={slug}>View full project</Link>
        <Link to={slug}>
          <CurrentProjectImg fluid={projectPost} />            
        </Link>
    </CurrentProjectWrapper>
  )
}

export default CurrentProject
