import React from 'react'
import styled from 'styled-components'
import Slider from 'react-slick'
import CurrentProject from '../components/CurrentProject'
import Slide from '../components/Slide'

import arrowRightDark from '../images/arrow-right-dark.svg'
import arrowLeftDark from '../images/arrow-left-dark.svg'

import arrowRight from '../images/arrow-right.svg'
import arrowLeft from '../images/arrow-left.svg'

import turnIcon from '../images/turn.svg'
import turnIconDark from '../images/turn-dark.svg'

// Slider
import '../styles/slick.css'

const Wrapper = styled.section`
  position: relative;
  min-height: 300px;
`

const Turn = styled.div`
  display: ${props => (props.vertical === true ? 'none' : 'block')};
  position: absolute;
  right: 30px;
  bottom: 35px;
  opacity: 0.7;
  z-index: 4;
  @media (min-width: ${props => props.theme.responsive.small}) {
    display: none;
  }
  &:before {
    content: url(${turnIconDark});
    width: 40px;
  }
`

const HeaderWrapper = styled.div`
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    height: 100vh;
    width: 100vw;
  }
  &.light {
    .slick-slider .slick-next:before {
      content: '';
      @media screen and (min-width: ${props => props.theme.responsive.medium}) {
        content: url(${arrowRight}) !important;
      }
    }
    .slick-slider .slick-prev:before {
      content: '';
      @media screen and (min-width: ${props => props.theme.responsive.medium}) {
        content: url(${arrowLeft}) !important;
      }
    }
    .next-text {
      color: white !important;
    }
    ${Turn} {
      &:before {
        content: url(${turnIcon}) !important;
      }
    }
    .slick-dots li button:before {
      color: white;
    }
    .slick-dots li.slick-active button:before {
      color: white;
    }
  }
  .slick-slider .slick-next:before {
    content: '';
    @media screen and (min-width: ${props => props.theme.responsive.medium}) {
      content: url(${arrowRightDark}) !important;
    }
  }
  .slick-slider .slick-prev:before {
    content: '';
    @media screen and (min-width: ${props => props.theme.responsive.medium}) {
      content: url(${arrowLeftDark}) !important;
    }
  }

  .slick-prev.slick-disabled,
  .slick-next.slick-disabled {
    display: none !important;
  }
`

const SlideWrapper = styled.div`
  position: relative;
  display: block;
  outline: none !important;
`
class HeroHeader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      oldSlide: 0,
      activeSlide: 0,
      activeSlide2: 0,
      headerTextVisible: this.props.fade,
      fade: this.props.fade,
    }
    this.sliderRef = React.createRef()
    this.handleBG = this.handleBG.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }
  handleClick() {
    if (this.state.headerTextVisible == true) {
      this.setState(state => ({
        headerTextVisible: false,
      }))
    }
  }

  handleBG() {
    if (this.state.activeSlide === 1 && this.state.fade === true) {
      this.setState(state => ({
        headerTextVisible: true,
      }))
    } else {
      this.setState(state => ({
        headerTextVisible: false,
      }))
    }
  }

  componentDidMount() {
    this.sliderRef.current.innerSlider.list.setAttribute('tabindex', 0)
    this.sliderRef.current.innerSlider.list.focus()

    this.timerHandle = setTimeout(() => {
      this.setState({ headerTextVisible: false })
      this.timerHandle = 0
    }, 2500)
  }

  componentWillUnmount = () => {
    if (this.timerHandle) {
      clearTimeout(this.timerHandle)
      this.timerHandle = 0
    }
  }

  render() {
    const settings = {
      draggable: true,
      arrows: true,
      dots: true,
      speed: 600,
      infinite: false,
      touchThreshold: 100,
      slidesToShow: 1,
      slidesToScroll: 1,
      beforeChange: (current, next) => {
        this.handleBG()
        this.setState({ oldSlide: current, activeSlide: next })
      },
      afterChange: current => {
        this.setState({
          activeSlide2: current,
        })
      },
    }
    // const previous = this.props.prev
    // const next = this.props.next
    // const title = this.props.title
    const images = this.props.posts
    // const subtitle = this.props.subtitle
    // var length = images.length - 1

    return (
      <Wrapper>
        <HeaderWrapper onClick={this.handleClick} id="headerWrapper">
          <Slider {...settings} ref={this.sliderRef}>
            {images.map((projectFiles, i) => (
              <SlideWrapper key={i}>
                <Slide
                  projectFiles={projectFiles.photoSet[0]}
                  id={i}
                  title={projectFiles.title}
                  subtitle={projectFiles.subtitle}
                  visible={this.state.headerTextVisible}
                  slideNumber={this.state.activeSlide}
                />
                <Turn vertical={projectFiles.vertical} />
                {projectFiles.photoSet[1] && (
                  <CurrentProject
                    dark={projectFiles.photoSet[0].dark}
                    slug={projectFiles.slug}
                    projectPost={projectFiles.photoSet[1].photo.fluid}
                  />
                )}
              </SlideWrapper>
            ))}
          </Slider>
        </HeaderWrapper>
      </Wrapper>
    )
  }
}

export default HeroHeader
